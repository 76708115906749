import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BiChevronDown } from "react-icons/bi";
import { Menu } from "@headlessui/react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import HandlingRateModal from "../Dashboard/HandlingRateModal";
import {
  fetchLoadedUnloadedData,
  fetchLoadingUnloadingCounts,
} from "../../feature/Location/LocationSlice"; // Import the thunk

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <div className="bg-white rounded-lg p-1 shadow-xl text-xs">
        <div className="flex gap-1">
          {payload.map((item, index) => (
            <div key={index} className="mr-1">
              <p className={`m-0 text-xs font-bold ${item.fill}`}>
                {item.name}
              </p>
              <p className="m-0 text-gray-500 text-xs opacity-0.5">{`${item.value} bags`}</p>
            </div>
          ))}
        </div>
        <p className="ml-4 text-xs text-teal-500">{data.name}</p>
      </div>
    );
  }
  return null;
};

const CustomLegend = () => (
  <div className="flex mb-2 text-xs">
    <div className="flex items-center mr-2">
      <span className="bg-teal-500 w-2 h-2 inline-block mr-1"></span>
      <span>Loaded</span>
    </div>
    <div className="flex items-center">
      <span className="bg-black w-2 h-2 inline-block mr-1"></span>
      <span>Unloaded</span>
    </div>
  </div>
);

const LoadedUnloadedData = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [localDuration, setLocalDuration] = useState("this_month"); // Local state for duration

  const handleClick = () => {
    setIsOpen(true);
  };

  const { locationSelected } = useSelector((state) => state.navbar);
  const { selectedCompany, loadedUnloadedData } = useSelector(
    (state) => state.location
  );
  const LoadedUnloadedCounts = useSelector(
    (state) => state.location.loadingUnloadingCounts
  );

  useEffect(() => {
    if (selectedCompany && locationSelected) {
      dispatch(
        fetchLoadedUnloadedData({
          cnf: selectedCompany,
          state: locationSelected,
          duration: localDuration, // Use local duration
        })
      );
      dispatch(
        fetchLoadingUnloadingCounts({
          cnf: selectedCompany,
          state: locationSelected,
          duration: localDuration, // Use local duration
        })
      );
    }
  }, [selectedCompany, locationSelected, localDuration, dispatch]); // Dependency array includes localDuration

  const handleDurationChange = (newDuration) => {
    setLocalDuration(newDuration); // Update local duration
  };

  const transformedData = (loadedUnloadedData || []).map((item) => ({
    name: item.truck_registration_number,
    Unloaded: item.total_unloaded_bags,
    Loaded: item.total_loaded_bags,
  }));

  const getPercentageClass = (percentageChange) => {
    if (percentageChange < 0) {
      return "text-[#f23b3b] bg-opacity-20 bg-[#f23b3b] px-1 border-transparent rounded text-xs mb-1";
    }
    return "text-[#24b466] bg-[#24b466] bg-opacity-20 px-1 border-transparent rounded text-xs mb-1";
  };

  const formatPercentageChange = (change) => {
    if (typeof change === "string") {
      return parseFloat(change.replace("%", ""));
    }
    return change;
  };

  return (
    <div className="p-3 bg-white rounded-md">
      <div className="flex items-center justify-between mb-2">
        <h1 className="text-lg font-semibold text-black">
          Loaded/Unloaded Bags
        </h1>
        <div className="flex items-center space-x-2 mb-2">
          <div
            className="p-1 bg-[#E5F7F8] text-[#126568] font-semibold rounded-md flex items-center text-xs cursor-pointer"
            onClick={handleClick}
          >
            View Details
          </div>
          <Menu as="div" className="relative">
            <Menu.Button className="flex items-center bg-[#E5F7F8] text-[#126568] text-xs py-1 px-2 rounded-md focus:outline-none">
              {localDuration === "this_week" ? "Current Week" : "Current Month"}
              <BiChevronDown
                className="h-4 w-4 ml-1"
                style={{ color: "#126568" }}
              />
            </Menu.Button>
            <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="p-1">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => handleDurationChange("this_week")}
                      className={`${
                        active ? "bg-blue-100 text-blue-900" : "text-gray-900"
                      } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                    >
                      Current Week
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => handleDurationChange("this_month")}
                      className={`${
                        active ? "bg-blue-100 text-blue-900" : "text-gray-900"
                      } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                    >
                      Current Month
                    </button>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Menu>
        </div>
      </div>
      <div className="flex justify-between items-start mb-2">
        <div className="flex gap-2">
          <div className="flex flex-col">
            <span className="text-sm text-[#757575] mb-1">Total Loaded</span>
            <span className="text-lg font-bold text-[#126568] mb-1">
              {LoadedUnloadedCounts
                ? LoadedUnloadedCounts.total_loaded_bags
                : "N/A"}
            </span>
            <span
              className={
                LoadedUnloadedCounts
                  ? getPercentageClass(
                      formatPercentageChange(
                        LoadedUnloadedCounts.loaded_percentage_change
                      )
                    )
                  : "text-[#757575]"
              }
            >
              {LoadedUnloadedCounts
                ? LoadedUnloadedCounts.loaded_percentage_change
                : "N/A"}
            </span>
          </div>
          <hr className="border-gray-300" />
          <div className="flex flex-col">
            <span className="text-sm text-[#757575] mb-1">Total Unloaded</span>
            <span className="text-lg font-bold text-[#126568] mb-1">
              {LoadedUnloadedCounts
                ? LoadedUnloadedCounts.total_unloaded_bags
                : "N/A"}
            </span>
            <span
              className={
                LoadedUnloadedCounts
                  ? getPercentageClass(
                      formatPercentageChange(
                        LoadedUnloadedCounts.unloaded_percentage_change
                      )
                    )
                  : "text-[#757575]"
              }
            >
              {LoadedUnloadedCounts
                ? LoadedUnloadedCounts.unloaded_percentage_change
                : "N/A"}
            </span>
          </div>
        </div>
        <CustomLegend />
      </div>
      <ResponsiveContainer width="100%" height={316}>
        <BarChart
          data={transformedData}
          layout="vertical"
          margin={{ top: 5, right: 20, left: -40 }}
        >
          <CartesianGrid
            strokeDasharray="3 3"
            horizontal={false}
            className="bg-gray-100"
          />
          <XAxis
            type="number"
            axisLine={false}
            tickLine={false}
            tick={{ fill: "#757575" }}
            className="text-xs"
            tickMargin={8}
          />
          <YAxis
            type="category"
            dataKey="name"
            axisLine={false}
            tickLine={false}
            tick={{ fill: "#757575" }}
            className="text-xs"
            width={150}
            interval={0}
            tickMargin={8}
          />
          <Tooltip
            content={<CustomTooltip />}
            cursor={{ fill: "transparent" }}
          />
          <Bar dataKey="Loaded" fill="#00ADB5" />
          <Bar dataKey="Unloaded" fill="#242325" />
        </BarChart>
      </ResponsiveContainer>
      <HandlingRateModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  );
};

export default LoadedUnloadedData;
