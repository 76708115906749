import React, { useState, useEffect } from 'react';
import appLogo from "../../Assets/SVG/app-logo.svg";
import { IoIosArrowBack } from "react-icons/io";
import homeIcon from "../../Assets/SVG/Sidebar/home.svg";
import stockIcon from "../../Assets/SVG/Sidebar/stock.svg";
import truckIcon from "../../Assets/SVG/Sidebar/truck.svg";
import locationIcon from "../../Assets/SVG/Sidebar/location.svg";
import userProfile from "../../Assets/Image/Sidebar/user-profile.png";
import logoutIcon from "../../Assets/SVG/Sidebar/logout.svg";
import valianceIcon from "../../Assets/SVG/Login-Container/valiancelogo.svg"
import { useNavigate, useLocation } from 'react-router-dom';

function Sidebar() {
  const dashboardData = [
    { label: "Home", icon: homeIcon, path: "/home" },
    // { label: "Stock", icon: stockIcon, path: "/stock" },
    { label: "Trucks", icon: truckIcon, path: "/truckinfo" },
    { label: "Locations", icon: locationIcon, path: "/location" }
  ];

  const navigate = useNavigate();
  const location = useLocation();
  const [currentDateTime, setCurrentDateTime] = useState({ date: '', time: '', am_pm: '' });

  useEffect(() => {
    const months = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    const updateDateTime = () => {
      const now = new Date();
      const year = now.getFullYear();
      const month = months[now.getMonth()];
      const day = now.getDate();
      const hours = now.getHours();
      const minutes = now.getMinutes();
      const seconds = now.getSeconds();

      const formattedHours = hours % 12 || 12;
      const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
      const formattedSeconds = seconds < 10 ? '0' + seconds : seconds;
      const ampm = hours < 12 ? 'AM' : 'PM';

      setCurrentDateTime({
        date: `${month} ${day}, ${year}`,
        time: `${formattedHours}:${formattedMinutes}:${formattedSeconds}`,
        am_pm: ampm
      });
    };

    const intervalId = setInterval(updateDateTime, 1000);

    updateDateTime();

    return () => clearInterval(intervalId);
  }, []);

  const handleLogout = () => {
    // Add logout logic here if needed (e.g., clearing tokens)
    navigate('/'); // Redirect to home page
  };

  return (
    <div className='w-[330px] h-screen p-2'>
      <div className='bg-[#FFFFFF] rounded-xl w-full h-full p-4'>
        <div className='w-full h-full flex flex-col justify-between'>
          <div className="flex flex-col gap-6">
            <div className='flex items-center justify-between'>
              <div className='flex items-center gap-3 text-[#242325]'>
                <img src={appLogo} alt="app" />
                <p className='text-[#242325] font-bold text-[1.125rem]'>Auto-Cement Count</p>
              </div>
              <div className='border p-1 rounded-full cursor-pointer' style={{ background: "linear-gradient(130.31deg, #126568 0%, #00ADB5 100%)" }}>
                <IoIosArrowBack color='white' size={24} />
              </div>
            </div>

            <div className='flex flex-col gap-4'>
              <div className='text-[#AAAAAA] font-semibold text-[1rem] leading-10' style={{ borderBottom: "1px solid #E5E5E5" }}>
                {/* Main Menu */}
              </div>
              <div className='flex flex-col gap-2 font-semibold text-[#126568]'>
                {
                  dashboardData.map((data, i) => (
                    <div
                      key={i}
                      onClick={() => navigate(data.path)}
                      className={`flex items-center gap-5 cursor-pointer border border-transparent rounded-lg px-2 py-2
                        ${location.pathname === data.path ? "bg-[#E5F7F8] text-black" : "hover:bg-[#E5F7F8] hover:text-black"}`}
                    >
                      <img src={data.icon} alt={data.label} className={location.pathname === data.path ? 'text-black' : ''} />
                      <span>{data.label}</span>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-5">
            <div>
              <div className='text-[#767676] font-semibold text-[1rem]'>{`${currentDateTime.date}`}</div>
              <div className='text-[#126568] font-bold text-[1.875rem] flex items-end gap-2'>
                {`${currentDateTime.time}`}
                <span className='text-[#767676] font-semibold text-[1rem]'>{`${currentDateTime.am_pm}`}</span>
              </div>
            </div>
            <div style={{ border: "1px solid #E5E5E5" }}></div>
            <div className='flex items-center gap-3'>
              <div className='w-[50px] h-[50px]'>
                <img src={userProfile} alt="profile" style={{ borderRadius: "80%" }} />
              </div>
              <div className='flex flex-col'>
                <div className='text-[1.25rem] text-[#242325] font-semibold'>Chitrank Tyagi</div>
                <div className='text-[0.875rem] text-[#767676] font-normal'>Valiance Solutions</div>
              </div>
            </div>
            <div className='flex flex-col gap-3 items-start'>
            <div className='flex items-center gap-3 cursor-pointer' onClick={handleLogout}>
              <img src={logoutIcon} alt="logout" />
              <span className='text-[#F23B3B] font-semibold hover:text-[red]'>Logout</span>
            </div>
            <div className='text-sm text-[#767676] font-bold gap-2 flex items-center'>
                Powered By 
                <div>
                  <img src={valianceIcon} alt="valiance" className='h-[22px]'/>
                </div>
              </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
