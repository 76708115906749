import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { FaMapMarkerAlt } from 'react-icons/fa';

const Marker = ({ color, onClick }) => (
  <div
    onClick={onClick}
    style={{
      color,
      fontSize: '14px',
      transform: 'translate(-50%, -50%)',
      cursor: 'pointer'
    }}
  >
    <FaMapMarkerAlt />
  </div>
);

function LocationMap({ onMarkerClick }) {

  const [locations, setLocations] = useState([]);
  const getAuthToken = () => localStorage.getItem("Authorization");

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await fetch("https://cement-automation-backend-vgoekfgvwa-el.a.run.app/api/cement_count_automation/map", {
          method: "GET",
          headers: {
            'Authorization': `Bearer ${getAuthToken()}`
          },
        });
        const data = await response.json();

        // Ensure that `data` is an array or handle it accordingly
        if (Array.isArray(data)) {
          setLocations(data);
        } else {
          console.error("Unexpected response format:", data);
          setLocations([]); // fallback to an empty array if it's not an array
        }
      } catch (error) {
        console.error("Error fetching locations:", error);
        setLocations([]); // Ensure we have an array even in case of an error
      }
    };

    fetchLocations();
  }, []);

  const defaultProps = {
    center: {
      lat: 21.990076,
      lng: 78.724945
    },
    zoom: 3.7
  };

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: `${process.env.REACT_APP_GOOGLE_API_KEY}` }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        {Array.isArray(locations) && locations.length > 0 ? (
          locations.map((location) => (
            <Marker
              key={location.id + location.latitude}
              lat={location.latitude}
              lng={location.longitude}
              color={location.type === 'factory' ? '#FF4444' : '#007BFF'}
              onClick={() => onMarkerClick(location['state-name'])}
            />
          ))
        ) : (
          <p style={{color: '#ff1515', width: '100%'}}>No locations available</p>
        )}
      </GoogleMapReact>
    </div>
  );
}

export default LocationMap;
